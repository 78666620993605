@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

@layer base {
  input {
    @apply rounded-3xl py-3 pl-6 font-medium text-lg placeholder:text-gray-500 focus:outline-none;
  }

  textarea {
    @apply rounded-3xl py-3 pl-6 font-medium text-lg text-gray-800 placeholder:text-gray-500 focus:outline-none;
  }
}
